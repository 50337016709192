import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getAllMatchesOddsCricketAPI } from "../../service/cricket";
import {
  getAllMatchesOddsSoccerAPI,
  getAllMatchesOddsTennisAPI,
} from "../../service/soccer&tennis";

const SportsPage = () => {
  const { sportId } = useParams();
  const [gameData, setGameData] = useState([]);
  const [selectedGameType, setSelectedGameType] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (sportId === "4") {
        setSelectedGameType("cricket");
        const { response, code } = await getAllMatchesOddsCricketAPI();
        if (code === 200) {
          setGameData(response);
        }
      } else if (sportId === "2") {
        setSelectedGameType("tennis");
        const { response, code } = await getAllMatchesOddsTennisAPI();
        if (code === 200) {
          setGameData(response);
        }
      } else if (sportId === "1") {
        setSelectedGameType("soccer");
        const { response, code } = await getAllMatchesOddsSoccerAPI();
        if (code === 200) {
          setGameData(response);
        }
      }
    };
    fetchData();
  }, [sportId]);

  return (
    <div className="center-main-container home-page">
      <div className="center-container">
        <div className="tab-content mt-1">
          <div className="tab-pane active">
            <div className="bet-table">
              <div className="bet-table-header">
                <div className="bet-nation-name">
                  <b>Game</b>
                </div>
                <div className="bet-nation-odd">
                  <b>1</b>
                </div>
                <div className="bet-nation-odd">
                  <b>X</b>
                </div>
                <div className="bet-nation-odd">
                  <b>2</b>
                </div>
              </div>
              <div className="bet-table-body">
                {gameData.map((game, index) => (
                  <div key={index} className="bet-table-row">
                    <div className="bet-nation-name">
                      <a
                        className="bet-nation-game-name"
                        href={`/game/${selectedGameType}/${game.eventId}`}
                      >
                        <span>{game.eventName}</span>
                        {moment(game.eventDate || game.startDate).format(
                          "DD-MM-YYYY HH:mm A"
                        )}
                      </a>
                      <div className="game-icons">
                        <div className="game-icon">
                          {game.inPlay && <span className="active" />}
                        </div>
                        <div className="game-icon">
                          <i className="fas fa-tv icon-tv" />
                        </div>
                        <div className="game-icon">
                            <img src={require("../../assets/ic_fancy.png")} />
                          </div>
                          <div className="game-icon">
                            <img src={require("../../assets/ic_bm.png")} />
                          </div>
                      </div>
                    </div>
                    {/* Odds */}
                    <div className="bet-nation-odd">
                      <div className="back odd-box">
                        <span className="bet-odd">{game.back1 || "-"}</span>
                      </div>
                      <div className="lay odd-box">
                        <span className="bet-odd">{game.lay1 || "-"}</span>
                      </div>
                    </div>
                    <div className="bet-nation-odd">
                      <div className="back odd-box">
                        <span className="bet-odd">
                          {game?.back11 ? game?.back11 : <b>-</b>}
                        </span>
                      </div>
                      <div className="lay odd-box">
                        <span className="bet-odd">
                          {game?.lay11 ? game?.lay11 : <b>-</b>}
                        </span>
                      </div>
                    </div>
                    <div className="bet-nation-odd">
                      <div className="back odd-box">
                        <span className="bet-odd">
                          {game?.back12 ? game?.back12 : <b>-</b>}
                        </span>
                      </div>
                      <div className="lay odd-box">
                        <span className="bet-odd">
                          {game?.lay12 ? game?.lay12 : <b>-</b>}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SportsPage;
