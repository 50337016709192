import React, { useEffect, useState } from "react";
import {
  getInternationalGamesAPI,
  getLaunchGameAPI,
} from "../../service/internationalCasino";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function InternationalCasino() {
  const [games, setGames] = useState({});
  const [iframeUrl, setIframeUrl] = useState("");
  const [currentGame, setCurrentGame] = useState("");
  const { id } = useParams();
  const userData = useSelector((state) => state.account.userData);

  const getInternationalGames = async () => {
    const { data } = await getInternationalGamesAPI();
    setGames(data);
  };

  const getLaunchGames = async (gameId,gameName) => {
    if (userData?.isDemo) {
      toast.error("You need to login as a real user to start this game.");
      return;
    }
    try {
      const { data } = await getLaunchGameAPI(gameId);
      if (data?.responseData?.url) {
        setIframeUrl(data.responseData.url);
        setCurrentGame(gameName)
        document.body.style.overflow = "hidden";
      } else {
        console.error("URL not found in the response");
      }
    } catch (error) {
      console.error("Error launching game:", error);
    }
  };

  const exitGame = () => {
    setIframeUrl(""); 
    setCurrentGame(""); 
    document.body.style.overflow = "auto"; 
  };

  useEffect(() => {
    getInternationalGames();
  }, []);
  return (
    <div className="center-main-container list-page own-casino-page">
      <div className="center-container">
        <div className="container-fluid container-fluid-5">
          <div className="row row5">
            <div className="col-xl-2 d-none d-xl-flex">
              <ul className="nav nav-pills casino-sub-tab">
                {games &&
                  Object.keys(games).length > 0 &&
                  Object.keys(games).map((item) => (
                    <li className="nav-item" key={item}>
                      <a
                        className={`nav-link ${
                          id
                            ? id == item && "active"
                            : item == "EVO" && "active"
                        }`}
                        href={`/international/${item}`}
                      >
                        <span>{item.toUpperCase()}</span>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="col-xl-10 col-12">
              <div className="casino-tab-list d-xl-none">
                <ul className="nav nav-pills casino-tab" id="casino-tab">
                  <li className="nav-item">
                    <a className="nav-link " href="/casino-page">
                      <span>Our Casino</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" href="/international">
                      <span>Our Virtual</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="casino-sub-tab-list d-xl-none">
                <ul
                  className="nav nav-pills casino-sub-tab"
                  id="casino-sub-tab"
                >
                  {games &&
                    Object.keys(games).length > 0 &&
                    Object.keys(games).map((item) => (
                      <li className="nav-item" key={item}>
                        <a
                          className={`nav-link ${
                            id
                              ? id == item && "active"
                              : item == "EVO" && "active"
                          }`}
                          href={`/international/${item}`}
                        >
                          <span>{item.toUpperCase()}</span>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="tab-content">
                <div className="tab-pane active" id="all-casino">
                  <div className="casino-list">
                    {games &&
                      Object.keys(games)?.length > 0 &&
                      games[id ? id : Object.keys(games)[0]]?.map((item) => (
                        <div className="casino-list-item" key={item.GameID}>
                          <a>
                            <div
                              className="casino-list-item-banner"
                              onClick={() => getLaunchGames(item.GameID,item.GameName)}
                              style={{
                                backgroundImage: `url(${item.ImageUrl})`,
                              }}
                            />
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {iframeUrl && (
          <div className="slot-iframe show">
            <div className="slot-header">
              <div className="title">
                <h4>{currentGame}</h4>
              </div>
              <div className="close-slot-frame" onClick={exitGame}>EXIT</div>
            </div>
            <iframe
              // scrolling="no"
              allow="fullscreen;"
              src={iframeUrl}
              style={{ width: "100%", border: 0 }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
