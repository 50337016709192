import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { currentBetsAPI } from "../../service/AuthService";

export default function BetHistory() {
  const [sport, setSport] = React.useState("cricket");
  const [isCasino, setIsCasino] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(50);
  const [data, setData] = React.useState([]);
  const [betType, setBetType] = React.useState("ALL");
  const [search, setSearch] = React.useState("");
  const [betStatus, setBetStatus] = React.useState("MATCHED");
  const [totalElements, setTotalElements] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [size, setSize] = React.useState(25);
  const [totalBets_, setTotalBets] = React.useState(0);
  const [totalAmount_, setTotalAmount] = React.useState(0);
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));

  const getCurrentBets = async () => {
    const {
      response: {
        totalAmount,
        totalBets,
        unsettledBets: { content, totalElements, totalPages, size },
      },
    } = await currentBetsAPI({
      betStatus,
      betType,
      pageSize,
      pageNumber,
      search,
      sports: sport !== "Casino",
    });
    let data_ = content.map((item) => ({
      ...item,
      rate: item.back_run ? item.back_run : item.lay_run,
      type: item.back_run ? "BACK" : "LAY",
    }));
    setData(data_?.filter((item) => item.event_type?.toLowerCase() === sport));
    setTotalElements(totalElements);
    setTotalPages(totalPages);
    setSize(size);
    setTotalBets(totalBets);
    setTotalAmount(totalAmount);
  };
  useEffect(() => {
    getCurrentBets();
  }, [pageNumber, pageSize, betType, search, betStatus, isCasino]);
  const isMobile = window.innerWidth < 786;

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
  };
  return (
    <div
      style={{
        width: isMobile ? "100%" : "82%",
      }}
      className={` report-main-content m-t-5`}
    >
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Bet History</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5">
          <div className="row row5 mt-2">
            <div className={isMobile ? "col-12" : "col-2"}>
              <div className="form-group mb-0">
                <select
                  onChange={(e) => {
                    setSport(e.target.value);
                    if (e.target.value === "Casino") {
                      setIsCasino(true);
                    } else {
                      setIsCasino(false);
                    }
                  }}
                  name="reportType"
                  className="custom-select"
                  value={sport}
                >
                  <option value="" disabled="disabled">
                    Sport Type
                  </option>
                  <option value={"football"}>Football</option>
                  <option value={"tennis"}>Tennis</option>
                  <option value={"cricket"}>Cricket</option>
                </select>
              </div>
            </div>
            <div className={isMobile ? "col-12 mt-2" : "col-2"}>
              <div className="form-group mb-0">
                <select
                  onChange={(e) => betType(e.target.value)}
                  name="reportType"
                  className="custom-select"
                  value={betType}
                >
                  <option value="" disabled="disabled">
                    Bet Status
                  </option>
                  <option value={"MATCHED"}>Matched</option>
                  <option value={"DELETED"}>Deleted</option>
                </select>
              </div>
            </div>
            <div className={isMobile ? "col-12 mt-2" : "col-2"}>
              <div className="form-group mb-0">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className={isMobile ? "col-12 mt-2" : "col-2"}>
              <div className="form-group mb-0">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div
              className={isMobile ? "col-12 mt-2" : "col-1 "}
              onClick={() => {
                getCurrentBets();
                setPageNumber(1);
              }}
            >
              <button className="btn btn-primary btn-block">Submit</button>
            </div>
          </div>
          <div className="row  row5 mt-2">
            <div className={isMobile ? "col-12 " : "col-2"}>
              <label
                style={{
                  marginLeft: "10px",
                }}
                className="col-form-label"
              >
                Size
              </label>
              <div className={isMobile ? "col-12" : "col-5"}>
                <select
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                  className="form-control form-control-sm"
                  value={pageSize}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12">
              <div className="table-responsive">
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount={8}
                  className="table b-table table-bordered"
                  id="__BVID__104"
                >
                  <thead role="rowgroup" className="">
                    <tr role="row" className="">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={1}
                        className="text-center"
                      >
                        Event Name
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={2}
                        className="text-center"
                      >
                        Nation
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={3}
                        className="text-center"
                      >
                        Bet Type
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={4}
                        className="text-center"
                      >
                        User Rate
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={5}
                        className="text-right"
                      >
                        Amount
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={6}
                        className="text-right"
                      >
                        Profit/Loss
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={7}
                        className="text-center"
                      >
                        Place Date
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={8}
                        className="text-center"
                      >
                        Match Date
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {data?.map((item, index) => (
                      <tr
                        role="row"
                        className={item?.back_run ? "back" : "lay"}
                        key={index}
                      >
                        <td role="cell" className="text-left">
                          {item.event_name}
                        </td>
                        <td role="cell" className="text-center">
                          {item.runner_name}
                        </td>
                        <td role="cell" className="text-center">
                          {item.event_type}
                        </td>
                        <td role="cell" className="text-center">
                          {item.rate}
                        </td>
                        <td role="cell" className="text-right">
                          {item.amount}
                        </td>
                        <td
                          role="cell"
                          className={`text-right`}
                          style={{ color: item.loss < 0 ? "red" : "green" }}
                        >
                          {item.loss ? item?.loss : item?.profit}
                        </td>
                        <td role="cell" className="text-center">
                          {moment(item?.created_date)?.format(
                            "DD:MM:YYYY HH:mm:ss"
                          )}
                        </td>
                        <td role="cell" className="text-center">
                          {moment(item?.created_date)?.format(
                            "DD:MM:YYYY HH:mm:ss"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12" />
          </div>
        </div>
      </div>
    </div>
  );
}
