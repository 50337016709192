import React from "react";
import Roulet from "./components/casinoGroup/Roulet";
import { useParams } from "react-router-dom";
import AllCasino from "./components/casinoGroup/AllCasino";
import TeenPati from "./components/casinoGroup/TeenPati";
import Poker from "./components/casinoGroup/Poker";
import Baccarats from "./components/casinoGroup/Baccarats";
import DragonTiger from "./components/casinoGroup/DragonTiger";
import ThirtyTwoCards from "./components/casinoGroup/ThirtyTwoCards";
import AndarBahar from "./components/casinoGroup/AndarBahar";
import Lucky7 from "./components/casinoGroup/Lucky7";
import ThreeCards from "./components/casinoGroup/ThreeCards";
import CasinoWar from "./components/casinoGroup/CasinoWar";
import Warli from "./components/casinoGroup/Warli";
import Sports from "./components/casinoGroup/Sports";
import Bollywood from "./components/casinoGroup/Bollywood";
import Queen from "./components/casinoGroup/Queen";
import Lottery from "./components/casinoGroup/Lottery";
import Race from "./components/casinoGroup/Race";
import OtherGames from "./components/casinoGroup/OtherGames";

export default function CasinoList() {
  const isMobile = window.innerWidth < 768;

  const param = useParams();
  const casinoMap = {
    roulet: <Roulet />,
    teenpati: <TeenPati />,
    poker: <Poker />,
    baccarat: <Baccarats />,
    dragontiger: <DragonTiger />,
    thirtytwo: <ThirtyTwoCards />,
    andarbahar: <AndarBahar />,
    luckyseven: <Lucky7 />,
    threecards: <ThreeCards />,
    casinowar: <CasinoWar />,
    warli: <Warli />,
    sports: <Sports />,
    bollywood: <Bollywood />,
    lottery: <Lottery />,
    queen: <Queen />,
    race: <Race />,
    othergames: <OtherGames />,
  };

  const isActive = (tabId) =>
    param?.id === tabId || (!param?.id && tabId === "all");

  return (
    <div
      style={{
        width: isMobile ? "100%" : "82%",
        marginTop: "-0px",
      }}
      className="main-container"
    >
      <ul className="nav nav-tabs d-xl-none menu-tabs">
        <li className="nav-item">
          <a className="nav-link" href="/sports-book/33">
            Lottery
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/home">
            Sports
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link active"
            href="/casino-page"
            aria-current="page"
          >
            Our Casino
          </a>
        </li>
        <li className="nav-item">
              <a className="nav-link" href="/international/SPRIBE">
                <span>Spribe</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/international/sevenMOJOS">
                <span>Seven Mojos</span>
              </a>
            </li>
        <li className="nav-item">
          <a className="nav-link" href="/fantasy-list">
            Fantasy
          </a>
        </li>
      </ul>
      <div
        style={{
          width: "100%",
        }}
        className="center-main-container list-page own-casino-page"
      >
        <div className="center-container">
          <div className="container-fluid container-fluid-5">
            <div className="row row5">
              <div className="col-xl-2 d-none d-xl-flex">
                <ul className="nav nav-pills casino-sub-tab">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${isActive("all") ? "active" : ""}`}
                      href="/casino-page"
                    >
                      <span>All Casino</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("roulet") ? "active" : ""
                      }`}
                      href="/casino-page/roulet"
                    >
                      <span>Roulette</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("teenpati") ? "active" : ""
                      }`}
                      href="/casino-page/teenpati"
                    >
                      <span>Teenpatti</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("poker") ? "active" : ""
                      }`}
                      href="/casino-page/poker"
                    >
                      <span>Poker</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("baccarat") ? "active" : ""
                      }`}
                      href="/casino-page/baccarat"
                    >
                      <span>Baccarat</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("dragontiger") ? "active" : ""
                      }`}
                      href="/casino-page/dragontiger"
                    >
                      <span>Dragon Tiger</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("thirtytwo") ? "active" : ""
                      }`}
                      href="/casino-page/thirtytwo"
                    >
                      <span>32 Cards</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("andarbahar") ? "active" : ""
                      }`}
                      href="/casino-page/andarbahar"
                    >
                      <span>Andar Bahar</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("luckyseven") ? "active" : ""
                      }`}
                      href="/casino-page/luckyseven"
                    >
                      <span>Lucky 7</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("threecards") ? "active" : ""
                      }`}
                      href="/casino-page/threecards"
                    >
                      <span>3 Card Judgement</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("casinowar") ? "active" : ""
                      }`}
                      href="/casino-page/casinowar"
                    >
                      <span>Casino War</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("warli") ? "active" : ""
                      }`}
                      href="/casino-page/warli"
                    >
                      <span>Worli</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("sports") ? "active" : ""
                      }`}
                      href="/casino-page/sports"
                    >
                      <span>Sports</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("bollywood") ? "active" : ""
                      }`}
                      href="/casino-page/bollywood"
                    >
                      <span>Bollywood</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("lottery") ? "active" : ""
                      }`}
                      href="/casino-page/lottery"
                    >
                      <span>Lottery</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("queen") ? "active" : ""
                      }`}
                      href="/casino-page/queen"
                    >
                      <span>Queen</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${isActive("race") ? "active" : ""}`}
                      href="/casino-page/race"
                    >
                      <span>Race</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        isActive("othergames") ? "active" : ""
                      }`}
                      href="/casino-page/othergames"
                    >
                      <span>Others</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-10 col-12">
                <div className="casino-tab-list d-xl-none">
                  <ul className="nav nav-pills casino-tab" id="casino-tab">
                    <li className="nav-item">
                      <a className="nav-link active" href="/casino-page">
                        <span>Our Casino</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="/international">
                        <span>Our Virtual</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="casino-sub-tab-list d-xl-none">
                  <a className="tabs-arow tabs-arow-left">
                    <i className="fas fa-angle-left" />
                  </a>
                  <ul
                    className="nav nav-pills casino-sub-tab"
                    id="casino-sub-tab"
                  >
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("all") ? "active" : ""
                        }`}
                        href="/casino-list/LC/4/35"
                      >
                        <span>All Casino</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("roulet") ? "active" : ""
                        }`}
                        href="/casino-page/roulet"
                      >
                        <span>Roulette</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("teenpati") ? "active" : ""
                        }`}
                        href="/casino-page/teenpati"
                      >
                        <span>Teenpatti</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("poker") ? "active" : ""
                        }`}
                        href="/casino-page/poker"
                      >
                        <span>Poker</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("baccarat") ? "active" : ""
                        }`}
                        href="/casino-page/baccarat"
                      >
                        <span>Baccarat</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("dragontiger") ? "active" : ""
                        }`}
                        href="/casino-page/dragontiger"
                      >
                        <span>Dragon Tiger</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("thirtytwo") ? "active" : ""
                        }`}
                        href="/casino-page/thirtytwo"
                      >
                        <span>32 Cards</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("andarbahar") ? "active" : ""
                        }`}
                        href="/casino-page/andarbahar"
                      >
                        <span>Andar Bahar</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("luckyseven") ? "active" : ""
                        }`}
                        href="/casino-page/luckyseven"
                      >
                        <span>Lucky 7</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("threecards") ? "active" : ""
                        }`}
                        href="/casino-page/threecards"
                      >
                        <span>3 Card Judgement</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("casinowar") ? "active" : ""
                        }`}
                        href="/casino-page/casinowar"
                      >
                        <span>Casino War</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("warli") ? "active" : ""
                        }`}
                        href="/casino-page/warli"
                      >
                        <span>Worli</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("sports") ? "active" : ""
                        }`}
                        href="/casino-page/sports"
                      >
                        <span>Sports</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("bollywood") ? "active" : ""
                        }`}
                        href="/casino-page/bollywood"
                      >
                        <span>Bollywood</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("lottery") ? "active" : ""
                        }`}
                        href="/casino-page/lottery"
                      >
                        <span>Lottery</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("queen") ? "active" : ""
                        }`}
                        href="/casino-page/queen"
                      >
                        <span>Queen</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("race") ? "active" : ""
                        }`}
                        href="/casino-page/race"
                      >
                        <span>Race</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isActive("othergames") ? "active" : ""
                        }`}
                        href="/casino-page/othergames"
                      >
                        <span>Others</span>
                      </a>
                    </li>
                  </ul>
                  <a className="tabs-arow tabs-arow-right">
                    <i className="fas fa-angle-right" />
                  </a>
                </div>
                {param?.id ? casinoMap[param.id] : <AllCasino />}
              </div>
            </div>
          </div>
          <div className="slot-iframe">
            <div className="slot-header">
              <div className="title">
                <h4 />
              </div>
            </div>
            <iframe
              src=""
              style={{ width: "100%", border: 0, background: "rgb(0, 0, 0)" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}