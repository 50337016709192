import { useSelector } from "react-redux";
import {
  casinoPL,
  cricketBookMakerPL,
  cricketFancyPL,
} from "../../utils/profitLoss";
import store from "../../redux/store";
import toast from "react-hot-toast";
import { cricketMatchOddsPL } from "../game/profitLoss";

export const getOddForEntity = (
  entity,
  odds,
  isBack = true,
  giveSize = false
) => {
  let data = odds?.data?.response?.oddDetailsDTOS?.find(
    (odd) => odd.runnerName?.toLowerCase() === entity?.toLowerCase()
  );
  if (data) {
    if (giveSize) {
      return isBack ? data.backSize1 : data.laySize1;
    }
    return isBack ? data.back1 : data.lay1;
  }
};

export const placeBetCasino = async (selectedEventForBet, dispatch) => {};

export const CARD_ARRAY = [
  "A",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "J",
  "Q",
  "K",
];

export const setBet = (
  runnerName,
  casinoData,
  dispatch,
  type = "back",
  market = false,
  takeSize = false
) => {
  const selectedEventForBet_ = store.getState().gameData.selectedEventForBet;
  let matchData = casinoData?.data?.response;

  let data = matchData?.oddDetailsDTOS?.find(
    (odd) => odd?.runnerName?.toLowerCase() === runnerName?.toLowerCase()
  );
  if (market) {
    data = matchData[market]?.find(
      (odd) => odd?.runnerName?.toLowerCase() === runnerName?.toLowerCase()
    );
  }

  if (!data) {
    toast.error("No data found for this event");
    return;
  }
  const isBack = type?.startsWith("back");

  if (!matchData) return;
  var game = "casino";
  let amount = selectedEventForBet_?.amount
    ? selectedEventForBet_?.amount
    : 100;
  let profit;
  let loss;

  if (takeSize) {
    profit = cricketFancyPL(
      isBack ? data?.backSize1 : data?.laySize1,
      amount,
      type == "back" ? "yes" : "no"
    ).profit;
    loss = (isBack ? data?.backSize1 : data?.laySize1,
    amount,
    type == "back" ? "yes" : "no").loss;
  } else {
    profit = cricketMatchOddsPL(isBack ? data?.back1 : data?.lay1, amount, type)
      .profit;
    loss = cricketMatchOddsPL(isBack ? data?.back1 : data?.lay1, amount, type)
      .loss;
  }

  if (data?.casinoGames == "CARD32EU" || data?.casinoGames == "CARD_32") {
    profit = cricketFancyPL(data?.back1, amount, type).profit;
    loss = cricketFancyPL(data?.back1, amount, type).loss;
  }
  let selectedEventForBet = {
    marketId: matchData?.marketId,
    type,
    selectedOdd: takeSize
      ? isBack
        ? data?.backSize1
        : data?.laySize1
      : isBack
      ? data?.back1
      : data?.lay1,
    selectedId: data?.selectionId,
    profit: profit,
    loss: loss,
    amount: amount,
    runnerName: data?.runnerName,
    isYesAndNo: false,
    isBack: isBack,
    takeSize,
    run: isBack ? data?.back1 : data?.lay1,
  };
  selectedEventForBet[type] = data?.back1;
  if (takeSize) {
    selectedEventForBet["size"] =
      type == "back" ? data?.backSize1 : data?.laySize1;
  }
  if (selectedEventForBet?.selectedOdd == 0) {
    return;
  }
  dispatch({
    type: "gameData/setSelectedEventForBet",
    payload: selectedEventForBet,
  });
};
export const getProfit = (selectedAmount, game, selectedEventForBet) => {
  let profit;
  let loss;
  let selectedAmount_ = selectedEventForBet.amount;
  if (game == "cricket" && selectedEventForBet.isBookmaker) {
    profit = cricketBookMakerPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = cricketBookMakerPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else if (game == "casino" || game == "tennis" || game == "soccer") {
    if (selectedEventForBet.takeSize) {
      profit = cricketFancyPL(
        selectedEventForBet?.selectedOdd,
        selectedAmount_,
        selectedEventForBet?.isBack ? "yes" : "no"
      )?.profit;
      loss = cricketFancyPL(
        selectedEventForBet?.selectedOdd,
        selectedAmount_,
        selectedEventForBet?.isBack ? "yes" : "no"
      )?.loss;
    } else {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    }
  } else if (game == "cricket" && selectedEventForBet.isFancy) {
    profit = cricketFancyPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = cricketFancyPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else {
    profit = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  }
  return {
    profit: parseFloat(profit).toFixed(2),
    loss: parseFloat(loss).toFixed(2),
  };
};
export const getCasinoPlExposureByRunner = (data, runnerName, selectionId) => {
  if (!data || !data?.length > 0) return 0;
  let exp = data[0]?.casinoPLExposure?.find(
    (exposure) =>
      runnerName?.toLowerCase() === exposure.selectionName?.toLowerCase()
  )?.exposure;
  return (
    <span
      style={{
        color: exp > 0 ? "green" : "red",
      }}
    >
      {exp}
    </span>
  );
};

export const getPointsMappingForCard = (cards) => {};
export const getPointsFromCard = (card_) => {
  let card = card_;
  //get number from card string if it is a number

  if (card[0] == "A") {
    return "ball1";
  }

  if (card[0] == "K") {
    return "wicket";
  }

  if (card.includes(10)) {
    return "ball0";
  }
  return "ball" + card[0];

  //if it is not a number then check for face cards
};
export const CASINO_VIDEO = {
  thirtyTwoCardA: "3055",
  dragonTiger2020: "3035",
  dragonTiger1Day: "3057",
  bollyWoodTable: "3041",
  amarAkbarAnthony: "3056",
  instantWorli: "3040",
  teenPattiT20: "3030",
  teenPatti1Day: "3031",
  teenPattiTest: "3048",
  poker2020: "3052",
  poker1Day: "3051",
  anderBahr1: "3053",
  lucky7A: "3058",
  thirtyTwoCardB: "3034",
  race2020: "3036",
  fiveFiveCricket: "3042",
  anderBahr2: "3043",
  lucky7B: "3032",
  openTeenPatti: "3049",
  dragonTiger22020: "3059",
  twentyTwentyCricket: "3045",
  superOver: "3060",
  baccarat1: "3044",
  baccarat2: "3033",
  casinoWar: "3038",
  twentyTwentyDTL: "3047",
  sixPlayerPoker: "30500",
  ballByBall: "3061,",
  casinoMeter: "3046",
  casinoQueen: "3037",
  cricketMatch20: "3045",
};
